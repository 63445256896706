//
//
//
//
//
//
//
//
//
//
//

import global from '@/mixins/global.js';
import RenderComponent from '@/components/RenderComponent.vue';
import query from '@/apollo/queries/dynamic-pages/query.gql';

export default {
  name: 'Home',
  components: {
    RenderComponent,
  },
  mixins: [global],
  async asyncData({ app, error }) {
    try {
      const client = app.apolloProvider.defaultClient;

      const { data } = await client.query({
        query,
        variables: { slug: 'home' },
      });

      const { metaTitle, metaDescription, noIndex, PageType } =
        data.dynamicPages[0];
      const seoData = {
        metaTitle,
        metaDescription,
        noIndex,
        pageType: PageType,
      };
      const strapiComponents = data.dynamicPages[0].Components;

      return (
        // if there aren't any components, might as well give them a 404
        strapiComponents.length > 0
          ? {
              strapiComponents,
              seoData,
            }
          : error({
              statusCode: 404,
              message: 'Page could not be found',
            })
      );
    } catch (err) {
      error({
        statusCode: 500,
        message: 'Internal server error',
      });
    }
  },
  data() {
    return {
      strapiComponents: null, // this is populated in asyncData
    };
  },
  head() {
    return {
      title: `${this.seoData?.metaTitle} | MindGym`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.seoData?.metaDescription ?? '',
        },
        {
          hid: 'robots',
          name: 'robots',
          content: this.seoData && this.seoData?.noIndex ? 'noindex' : 'index',
        },
      ],
      link: [this.$getCanonicalLink('')],
      script: [
        {
          src: '~/utils/googleTagManager.js',
        },
      ],
    };
  },
  mounted() {
    this.firePageViewGTMEvt(
      this.seoData?.pageType,
      this.$route.path,
      this.seoData?.metaTitle,
    );
  },
};
